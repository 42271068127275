export const booking = [
  "Flights",
  "Hotels",
  "Car Rentals",
  "Tours",
  "Restaurant",
  "Shop",
]
export const about = [
  "About us",
  "Terms and Conditions",
  "Privacy policy",
  "Information Security",
  "Operational Regulations",
  "FAQs",
]

export interface ContactFooter {
  origin: string
  phone: string
  mail: string
  address: string
}
export const contact: ContactFooter[] = [
  {
    origin: "US",
    phone: "+1 402 580 7167",
    mail: `contact.food.us@${process.env.REACT_APP_TITLE?.toLowerCase()}.com`,
    address: "5104 N Orange Blossom Trl, Orlando, FL 32810, United States",
  },
  {
    origin: "Vietnam",
    phone: "+84 1900 571253",
    mail: `contact.food.vn@${process.env.REACT_APP_TITLE?.toLowerCase()}.com`,
    address:
      "400/8B-400/10, Ung Văn Khiêm, Phường 25, Quận Bình Thạnh, TP.HCM, Việt Nam",
  },
  {
    origin: "India",
    phone: "+ 91 33 4802 5174",
    mail: `contact.food.in@${process.env.REACT_APP_TITLE?.toLowerCase()}.com`,
    address:
      "9th Floor, Aurora Waterfront, Unit No 902, Plot 34/1, GN Block, Kolkata, West Bengal 700091, India",
  },
]

export const otherServices = [
  "Become our partner",
  "Affiliate program",
  "Submit feedback",
]
